export function concatAuthParamsToQuery(
  url: string,
  params: { [key: string]: string }
): string {
  const authParams = [];
  for (const [key, value] of Object.entries(params)) {
    authParams.push(`${key}=${value}`);
  }
  const query = authParams.length
    ? `?auth_params=${encodeURIComponent(authParams.join('&'))}`
    : '';

  return `${url}${query}`;
}
