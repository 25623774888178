import React from 'react';
import ReactDOMClient from 'react-dom/client';
import type { Core } from './types';
import actions from './actions';
import dom from './helpers/dom';
import {
  profile,
  setProfile,
  articlesMeta,
  setArticlesMeta,
  subscriptions,
  setSubscriptions,
  recentSocialId,
  setRecentSocialId,
  articleStats,
  setArticleStats,
} from './resources';
import AuthController from './controllers/auth';
import ArticlesMetaController from './controllers/meta';

import { getCore, isCoreInitialized, setCore } from './helpers/window';
import SubscriptionsController from './controllers/subscriptions';
import ArticleStatsController from './controllers/stats';

export default function init(): Core | undefined {
  if (typeof window === 'undefined') {
    return undefined;
  }

  if (typeof React === 'undefined' || typeof ReactDOMClient === 'undefined') {
    throw new Error(
      'facade did not provide LIBS[react, react-dom] required for shared core'
    );
  }

  if (isCoreInitialized()) {
    return getCore();
  }

  AuthController.initialize(profile, setProfile, setRecentSocialId);
  ArticlesMetaController.initialize(articlesMeta, setArticlesMeta);
  SubscriptionsController.initialize(subscriptions, setSubscriptions);
  ArticleStatsController.initialize(articleStats, setArticleStats);

  const core: Core = {
    actions,
    dom,
    profile,
    articlesMeta,
    articleStats,
    subscriptions,
    recentSocialId,
  };

  setCore(core);

  return core;
}
