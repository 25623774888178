import SubscriptionsController from '../controllers/subscriptions';
import type { SubscribeToTopicParams } from '../types';

export async function requestSubscriptions() {
  return SubscriptionsController.current.getSubscriptions();
}

export async function requestTopicSubscription(params: SubscribeToTopicParams) {
  return SubscriptionsController.current.subscribeToTopic(params);
}
