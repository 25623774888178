/* eslint-disable @typescript-eslint/no-unused-vars  */
/* eslint-disable jest/no-mocks-import */

import type { MercuryAdapter } from '../mercury';

const mockMercuryAdapter: MercuryAdapter = {
  stats: {
    get() {
      return Promise.resolve({
        favorites: 194,
        views: 151595,
        likes: 98,
        comments: 10,
      });
    },
  },
};

export default mockMercuryAdapter;

/* eslint-enable @typescript-eslint/no-unused-vars  */
/* eslint-enable jest/no-mocks-import */
