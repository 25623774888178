import type { SubscribeToTopicParams } from '../types';
import { social, urania } from '../adapters';

type Props = SubscribeToTopicParams & {
  socialId?: number;
};

export const subscribe = ({
  socialId,
  provider = 'mindbox',
  pointOfContact,
  topic,
  email,
  resourceKey,
  resourceType,
}: Props) => {
  if (provider === 'urania') {
    return urania.subscriptions.subscribe({
      socialId,
      email,
      resourceKey,
      topic,
      pointOfContact,
      resourceType,
    });
  }

  return social.subscriptions.subscribeToTopic(email, [topic], pointOfContact);
};
