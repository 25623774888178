import type { Resource } from '../types';

export default function makeResource<T>(
  initialValue: T
): [Resource<T>, (newValue: T) => void] {
  let value = initialValue;

  function get() {
    return value;
  }

  function wait() {
    return new Promise<T>((resolve) => {
      const unsubscribe = subscribe((newValue) => {
        unsubscribe();
        resolve(newValue);
      });
    });
  }

  const subscribers = new Set<(newValue: T) => void>();

  function subscribe(cb: (newValue: T) => void) {
    subscribers.add(cb);
    return () => subscribers.delete(cb);
  }

  function set(newValue: T) {
    subscribers.forEach((cb) => cb(newValue));
    value = newValue;
  }

  return [{ get, wait, subscribe }, set];
}
