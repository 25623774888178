import type { ActionStatus, RequestLoginParams } from '../types';
import AuthController from '../controllers/auth';

export async function requestLogin(
  params: RequestLoginParams
): Promise<ActionStatus> {
  return AuthController.current.login(params);
}

export async function requestLogout(): Promise<ActionStatus> {
  return AuthController.current.logout();
}

export async function requestProfileRefresh() {
  return AuthController.current.getProfile();
}
