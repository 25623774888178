import { requestLogin, requestLogout, requestProfileRefresh } from './auth';
import {
  requestAddArticleFavorite,
  requestAddArticleVote,
  requestArticlesMeta,
  requestClearMeta,
  requestRemoveArticleFavorite,
  requestRemoveArticleVote,
} from './meta';
import {
  requestSubscriptions,
  requestTopicSubscription,
} from './subscriptions';
import { requestArticleStats } from './stats';

export default {
  requestLogin,
  requestLogout,
  requestArticlesMeta,
  requestAddArticleFavorite,
  requestAddArticleVote,
  requestRemoveArticleFavorite,
  requestRemoveArticleVote,
  requestClearMeta,
  requestSubscriptions,
  requestTopicSubscription,
  requestProfileRefresh,
  requestArticleStats,
};
