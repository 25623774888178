import { mercury } from '../adapters';
import type { ArticleStats, Resource } from '../types';

export default class ArticleStatsController {
  private statsPromise: Promise<void> | null = null;

  static current: ArticleStatsController;
  private constructor(
    private resource: Resource<ArticleStats>,
    private updateResource: (newValue: ArticleStats) => void
  ) {
    this.init();
  }

  async getStats(articleId: string) {
    if (!this.statsPromise) {
      this.statsPromise = this.getStatsPromise(articleId);
    }
    await this.statsPromise;
    this.statsPromise = null;
  }

  private async getStatsPromise(articleId: string) {
    const { favorites, likes, views, comments } =
      await mercury.stats.get(articleId);
    this.updateResource({
      favoritesCount: favorites,
      commentsCount: comments,
      likes,
      views,
      // TODO: Дизлайки для статей не используются, нужно удалить, после того, как убедимся, что ничего не ломается
      dislikes: 0,
    } as ArticleStats);
  }

  private init() {
    if (window.TJ_ARTICLE_META?.articleId) {
      this.getStats(window.TJ_ARTICLE_META.articleId);
    }
  }

  static initialize(
    resource: Resource<ArticleStats>,
    updateResource: (newValue: ArticleStats) => void
  ) {
    if (ArticleStatsController.current) {
      throw new Error('Already initialized');
    }

    ArticleStatsController.current = new ArticleStatsController(
      resource,
      updateResource
    );
  }
}
