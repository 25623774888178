import type { ToggleElementVisibilityParams } from '../../types';

// Возвращает true, если скрытие элемента произошло успешно
export const toggleElementVisibility = ({
  element,
  isHidden,
}: ToggleElementVisibilityParams): boolean => {
  const currentElement =
    typeof element === 'string'
      ? document.querySelector<HTMLElement>(`#${element}`)
      : element;

  const hiddenStyle = isHidden ? 'hidden' : '';

  if (currentElement) {
    // Скрываем элемент именно таким способом, потому что есть кейсы,
    // в которых необходима возможность вычислять высоту скрытого элемента
    currentElement.style.visibility = hiddenStyle;
    currentElement.style.height = isHidden ? '0' : '';
    currentElement.style.overflow = hiddenStyle;
  }

  return currentElement?.style.visibility === hiddenStyle;
};
