import { uraniaApi } from '../helpers/http-api';
import type { SubscriptionResource } from '../types';

type Props = {
  socialId?: number;
  email: string;
  pointOfContact?: string;
  topic: string;
  resourceKey?: string;
  resourceType?: SubscriptionResource;
};

const uraniaAdapter = {
  subscriptions: {
    async subscribe({
      socialId,
      resourceKey,
      email,
      pointOfContact,
      topic,
      resourceType = 'course',
    }: Props) {
      return uraniaApi.post(`/users/subscribe/`, {
        email,
        // Условия для обратной совместимости
        topic: resourceKey ? topic : undefined,
        resource_key: resourceKey || topic,
        point_of_contact: pointOfContact,
        social_id: socialId,
        resource_type: resourceType,
      });
    },
  },
};

export default uraniaAdapter;

export type UraniaAdapter = typeof uraniaAdapter;
