import { createElement } from 'react';
import ReactDOMClient from 'react-dom/client';
import type { SubscriptionFormProps } from '../../types';

export function renderSubscriptionForm(
  element: HTMLElement,
  props: SubscriptionFormProps
) {
  const root = ReactDOMClient.createRoot(element);

  // До того как форма отрендерилась первый раз, rerender просто подменяет пропсы на новые
  let rerender = (newProps: SubscriptionFormProps) => {
    // eslint-disable-next-line no-param-reassign
    props = newProps;
  };

  // Динамически импортируем форму подписки, чтобы не тянуть ее если она не нужна
  // Используем промисы вместо async/await, чтобы api оставался синхронным, т.к. на это
  // уже заточен внешний код
  import('../../presentation/subscription-form/subscription-form').then(
    ({ default: SubscriptionForm }) => {
      root.render(createElement(SubscriptionForm, props));

      // После первого рендера ререндер реально вызывает root.render с переданными пропсами
      rerender = (newProps: SubscriptionFormProps) => {
        root.render(createElement(SubscriptionForm, newProps));
      };
    }
  );

  // Должна быть возможность снаружи менять пропсы формы, для этого отдаем наружу
  // функцию для вызова ререндера
  return (newProps: SubscriptionFormProps) => rerender(newProps);
}
