import MetaController from '../controllers/meta';

/**
 *
 * @param options.isFullFavorite - запросить все закладки без лимитов <= 100
 * @private
 */
export async function requestArticlesMeta(options?: {
  isFullFavorite?: boolean;
}) {
  return MetaController.current.getMeta(options);
}

export async function requestAddArticleFavorite(uuid: string) {
  return MetaController.current.addFavorite(uuid);
}

export async function requestAddArticleVote(uuid: string) {
  return MetaController.current.addVote(uuid);
}

export async function requestRemoveArticleFavorite(uuid: string) {
  return MetaController.current.removeFavorite(uuid);
}

export async function requestRemoveArticleVote(uuid: string) {
  return MetaController.current.removeVote(uuid);
}

export async function requestClearMeta() {
  return MetaController.current.clearMeta();
}
