import { getCookie } from './get-cookie';

const MINDBOX_DEVICE_UUID_KEY = 'mindboxDeviceUUID';

export function getMindboxDeviceUuid(): string | undefined {
  const deviceUuidFromCookie = getCookie(MINDBOX_DEVICE_UUID_KEY);
  const deviceUuidFromLocalStorage =
    localStorage.getItem(MINDBOX_DEVICE_UUID_KEY) || undefined;
  // Проверяем наличие deviceUuid в сookie и localStorage.
  // В 99% случаев он должен там лежать. Если вдруг по каким-то
  // причинам скрипт майндбокса не отработал и не проставил куку
  // с deviceUuid, то сами его генерим
  const deviceUuid = deviceUuidFromCookie || deviceUuidFromLocalStorage;

  // Если в localStorage нет deviceUuid, то записываем туда
  // полученный из кук
  if (!deviceUuidFromLocalStorage && deviceUuid) {
    localStorage.setItem(MINDBOX_DEVICE_UUID_KEY, deviceUuid);
  }

  return deviceUuid;
}
