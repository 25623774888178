import { social } from '../adapters';
import { profile } from '../resources';
import type { Resource, SubscribeToTopicParams, Subscriptions } from '../types';
import { subscribe } from '../helpers/subscribe';

export default class SubscriptionsController {
  static current: SubscriptionsController;
  private constructor(
    private resource: Resource<Subscriptions>,
    private updateResource: (newValue: Subscriptions) => void
  ) {}

  async getSubscriptions() {
    const subscriptions = await social.subscriptions.get();

    this.updateResource({
      flows: subscriptions.flows,
      topics: subscriptions.email,
      isEmailSaved: subscriptions.email_is_saved,
    });
  }

  async subscribeToTopic({
    email,
    topic,
    pointOfContact,
    provider = 'mindbox',
    resourceKey,
    resourceType,
  }: SubscribeToTopicParams) {
    if (!email || !topic || !pointOfContact) {
      console.error('Email, topic and pointOfContact params are required');
      return;
    }

    const socialId = profile.get()?.id;

    return subscribe({
      email,
      topic,
      pointOfContact,
      provider,
      socialId,
      resourceKey,
      resourceType,
    });
  }

  static initialize(
    resource: Resource<Subscriptions>,
    updateResource: (newValue: Subscriptions) => void
  ) {
    if (SubscriptionsController.current) {
      throw new Error('Already initialized');
    }
    SubscriptionsController.current = new SubscriptionsController(
      resource,
      updateResource
    );
  }
}
