import type { LoginProviderName } from '../presentation/auth-popup/components/social-networks/providers';

export function buildLoginEvent(
  pointOfContact: string,
  provider: LoginProviderName
) {
  return new CustomEvent('shared-core:login', {
    detail: { pointOfContact, provider },
  });
}

export type LoginEvent = ReturnType<typeof buildLoginEvent>;
