type RequestFn<T> = (params: {
  limit: number;
  offset?: number;
}) => Promise<{ data: T[]; count: number }>;

export async function fetchAll<T>(requestFn: RequestFn<T>, limit: number) {
  let response = await requestFn({ limit });
  let result = [...response.data];
  const totalCount = response.count;

  for (
    let currentOffset = limit;
    currentOffset < totalCount;
    currentOffset += limit
  ) {
    response = await requestFn({ limit, offset: currentOffset });
    result = result.concat(response.data);
  }

  return {
    count: totalCount,
    data: result,
  };
}
