import type { ScrollParams } from '../../types';

export const getElementTop = (element: HTMLElement): number =>
  element.getBoundingClientRect().top -
  document.documentElement.getBoundingClientRect().top;

/**
 * Прокручивает страницу до некоторого элемента на странице
 * @param element - элемент страницы или айдишник этого элемента
 * @param elementTargetPosition - в какой части окна просмотра должен оказаться элемент
 * @param duration - длительность анимации
 * @param offset - добавить отклонение от целевой позиции прокрутки
 */
export const scrollToElement = (
  element: HTMLElement | string,
  { elementTargetPosition = 'start', duration = 600, offset = 0 }: ScrollParams
): void => {
  const currentElement =
    typeof element === 'string'
      ? document.querySelector<HTMLElement>(`#${element}`)
      : element;

  if (!currentElement) {
    return;
  }

  const startY =
    window.scrollY || window.pageYOffset || document.documentElement.scrollTop;

  let diffY = getElementTop(currentElement) - startY + offset;
  if (elementTargetPosition === 'center') {
    diffY -= window.innerHeight / 2 - currentElement.clientHeight / 2;
  } else if (elementTargetPosition === 'end') {
    diffY -= window.innerHeight - currentElement.clientHeight;
  }

  let startTime: number;
  const step = (timestamp: number) => {
    if (!startTime) {
      startTime = timestamp;
    }
    const elapsedTime = timestamp - startTime;
    const timeRatio = Math.min(elapsedTime / duration, 1);

    let t = timeRatio * 2;

    if (t < 1) {
      window.scrollTo(0, startY + (diffY / 2) * t ** 2);
    } else {
      t -= 2;
      window.scrollTo(0, startY - (diffY / 2) * (t ** 4 - 2));
    }

    if (elapsedTime < duration) {
      window.requestAnimationFrame(step);
    }
  };

  window.requestAnimationFrame(step);
};
