import type { Core, SharedCoreHolder } from '../types';

export function isCoreInitialized(
  wnd: SharedCoreHolder | null = getDefaultHolder() as SharedCoreHolder
) {
  return Boolean(wnd?.tjlib?.core);
}

export function setCore(
  core: Core,
  wnd: SharedCoreHolder | null = getDefaultHolder() as SharedCoreHolder
) {
  if (!wnd) {
    throw new Error('unable to initialize shared core without holder');
  }
  if (isCoreInitialized(wnd)) {
    throw new Error('tjlib.core is already initialized');
  }
  wnd.tjlib = wnd.tjlib || {};
  wnd.tjlib.core = core;
}

export function getCore(
  wnd: SharedCoreHolder | null = getDefaultHolder() as SharedCoreHolder
) {
  return wnd?.tjlib?.core;
}

function getDefaultHolder() {
  return typeof window !== 'undefined' ? window : null;
}
