import type { ArticlesMeta, Resource } from '../types';
import { social } from '../adapters';
import { fetchAll } from '../helpers/fetch-all';

const EMPTY_META = {
  favorites: [],
  favoritesCount: 0,
  votes: [],
  downVotes: [],
};
const REQUEST_LIMIT = 100;

export default class ArticlesMetaController {
  private metaPromise: Promise<void> | null = null;

  static current: ArticlesMetaController;
  private constructor(
    private resource: Resource<ArticlesMeta>,
    private updateResource: (newValue: ArticlesMeta) => void
  ) {}

  /**
   *
   * @param options.isFullFavorite - запросить все закладки без лимитов <= 100
   * @private
   */
  async getMeta(options?: { isFullFavorite?: boolean }) {
    // Сохраняем промис отдельно и дожидаемся его выполнения,
    // чтобы нельзя было одновременно несколько раз запросить и обновить ресурс
    if (!this.metaPromise) {
      this.metaPromise = this.getMetaPromise(options);
    }
    await this.metaPromise;
    this.metaPromise = null;
  }

  async clearMeta() {
    this.updateResource(null);
  }

  /**
   * Выполняем операции "оптимистично" - сразу предполагаем что операция
   * будет выполнена успешно, в противном случае откатываем операцию.
   * Актуально для addFavorite, addVote, removeFavorite и removeVote.
   */
  async addFavorite(uuid: string) {
    if (this.metaPromise) {
      await this.metaPromise;
    }
    this.updateFavorite(uuid, 'add');
    try {
      await social.meta.add.favorite(uuid);
    } catch {
      this.updateFavorite(uuid, 'remove');
      return 'fail';
    }
    return 'success';
  }

  async addVote(uuid: string) {
    if (this.metaPromise) {
      await this.metaPromise;
    }
    this.moveVote(uuid, { from: 'downVotes', to: 'votes' });
    try {
      await social.meta.add.vote(uuid);
    } catch {
      this.moveVote(uuid, { from: 'votes', to: 'downVotes' });
      return 'fail';
    }
    return 'success';
  }

  async removeFavorite(uuid: string) {
    this.updateFavorite(uuid, 'remove');
    try {
      await social.meta.remove.favorite(uuid);
    } catch {
      this.updateFavorite(uuid, 'add');
      return 'fail';
    }
    return 'success';
  }

  async removeVote(uuid: string) {
    this.moveVote(uuid, { from: 'votes', to: 'downVotes' });
    try {
      await social.meta.remove.vote(uuid);
    } catch {
      this.moveVote(uuid, { from: 'downVotes', to: 'votes' });
      return 'fail';
    }
    return 'success';
  }

  private updateFavorite(uuid: string, action: 'add' | 'remove') {
    const oldValue = this.resource.get() ?? EMPTY_META;

    let newFavorites: string[] = [];
    let { favoritesCount } = oldValue;
    if (action === 'add') {
      newFavorites = [...oldValue.favorites, uuid];
      favoritesCount += 1;
    } else {
      newFavorites = oldValue.favorites.filter((favorite) => favorite !== uuid);
      favoritesCount -= 1;
    }

    const newValue = { ...oldValue, favorites: newFavorites, favoritesCount };
    this.updateResource(newValue);
  }

  /**
   * В соцплатформе у каждой статьи есть свой рейтинг оценки от пользователя user_score: -1, 0, 1.
   * И методы addVote и removeVote повышают или понижают этот рейтинг на 1.
   */
  private moveVote(
    uuid: string,
    direction: { from: 'votes' | 'downVotes'; to: 'votes' | 'downVotes' }
  ) {
    const oldValue = this.resource.get() ?? EMPTY_META;

    let from = oldValue[direction.from];
    let to = oldValue[direction.to];

    if (from.includes(uuid)) {
      // Если оценка есть в исходном массиве, то удаляем оттуда и рейтинг становится нейтральным
      from = from.filter((u: string) => u !== uuid);
    } else if (!to.includes(uuid)) {
      // Если рейтинг был нейтральным, то добавляем оценку
      to = [...to, uuid];
    }

    this.updateResource({
      ...oldValue,
      [direction.from]: from,
      [direction.to]: to,
    });
  }

  /**
   * @param options.isFullFavorite - запросить все закладки без лимитов <= 100
   * @private
   */
  private async getMetaPromise(options?: { isFullFavorite?: boolean }) {
    const [favorites, votes] = await Promise.all([
      this.getFavorites(options?.isFullFavorite),
      social.meta.get.votes(),
    ]);

    const uuidFavorites = favorites.data.map(
      (favorite) => favorite.article_uuid
    );
    const uuidVotes = votes
      .filter((v) => v.user_vote > 0)
      .map((f) => f.article_uuid);
    const uuidDownVotes = votes
      .filter((v) => v.user_vote < 0)
      .map((f) => f.article_uuid);

    this.updateResource({
      favorites: uuidFavorites,
      favoritesCount: favorites.count,
      votes: uuidVotes,
      downVotes: uuidDownVotes,
    });
  }

  /**
   *
   * @param allFavorites - запросить все закладки пользователя, по умолчанию возвращается <= 100
   * @private
   */
  private async getFavorites(allFavorites?: boolean) {
    if (!allFavorites) {
      return social.meta.get.favorites({ limit: REQUEST_LIMIT });
    }

    return fetchAll(social.meta.get.favorites, REQUEST_LIMIT);
  }

  static initialize(
    resource: Resource<ArticlesMeta>,
    updateResource: (newValue: ArticlesMeta) => void
  ) {
    if (ArticlesMetaController.current) {
      throw new Error('Already initialized');
    }
    ArticlesMetaController.current = new ArticlesMetaController(
      resource,
      updateResource
    );
  }
}
