import type { LoginProviderName } from './providers';
import { socialApi } from '../../../../helpers/http-api';

export type SocialApiLoginUrlOptions = {
  provider: LoginProviderName;
  // Точка контакта нужна для определения откуда произошёл логин.
  // Используется в аналитике и рассылках.
  // В качестве точки контакта можно использовать любую человеко-
  // читаемую строку, лучше с префиксом проекта/компонента/страницы
  // Например: pro-courseSlug
  point_of_contact: string;
  // fallback_redirect_url используется для редиректа пользователя
  // см «Альтернативный поток для браузеров на основе WebView» в
  // auth-flows.md
  fallback_redirect_url?: string;
  // Необходимо для корректной склейки пользователей в mindbox.
  tj_device_id?: string;
  // Для склеивания карточек в майндбоксе юзеров, авторизовавшихся
  // для активации ранее купленного без авторизации курса Учебника
  promocode?: string;
  // Необходимо для google аналитики
  // google_client_id: string, TODO аналитика
};

const WINDOW_CLOSING_TIMEOUT = 5 * 60 * 1000;
const WINDOW_CLOSING_POLLING_INTERVAL = 200;

let authWindow: Window | undefined;

/**
 * Предварительно инициализируем, чтобы удовлетворить синхронности вызова для сафари.
 */
export function initAuthWindow() {
  authWindow = window.open('about:blank') || undefined;
}

/**
 * Закрываем при необходимости
 */
export function forceCloseAuthWindow() {
  if (authWindow && !authWindow.closed) {
    authWindow.close();
  }
}

/**
 * Открывает окно аутентификации, ждёт его закрытия,
 * посылает запрос на сервер для подтверждения того,
 * что пользователь аутентифицировался.
 * @returns Promise<true> если пользователь аутентифицировался
 *          Promise<false> если пользователь не прошёл аутен-
 *          фикацию или сервер ответил кодом отличным от 200
 */
export function doSocialAuthRequestInNewWindow(
  authUrl: string
): Promise<boolean> {
  createNewWindowWithSocialAuthRequest(authUrl);
  return waitForClosing(authWindow);
}

export function createNewWindowWithSocialAuthRequest(authUrl: string): void {
  if (authWindow && !authWindow.closed) {
    authWindow.focus();
    authWindow.location.replace(authUrl);
  }
}

/**
 * Аутентификационное окно проходит цепочку редиректов,
 * после чего закрывается само если аутентификация прошла
 * успешно.
 *
 * Но аутентификация может провалиться из-за недостатка прав,
 * корявых настроек сервера или глючащего провайдера.
 * В таком случае пользователь просто закроет окно сам.
 * Вообще пользователь может закрыть окно сам просто так.
 *
 * Потому стоит использовать положительный ответ этого метода
 * как повод сходить на сервер и проверить прошла ли аутентификация.
 *
 * @returns Promise<true> если окно закрыли
 *          Promise<false> если окно не закрыли
 *                         в течении WINDOW_CLOSING_TIMEOUT
 */
export async function waitForClosing(
  authWindow?: Window,
  closingTimeout = WINDOW_CLOSING_TIMEOUT
): Promise<boolean> {
  const deadlineTime = Date.now() + closingTimeout;
  while (Date.now() < deadlineTime) {
    if (!authWindow || authWindow.closed) {
      return true;
    }
    await wait(WINDOW_CLOSING_POLLING_INTERVAL);
  }
  return false;
}

export async function wait(timer: number): Promise<undefined> {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(undefined);
    }, timer);
  });
}

/**
 * Функция разруливает этап ответа на запрос авторизации
 * через Social Networks.
 * @see SocialFetchData
 * @see createAuthFetch
 * @returns Promise<string> for success case
 * @returns Promise<undefined> for trouble case
 * @param authUrl
 * @param authBody
 */
export async function doSocialAuthFetch(
  authUrl: string,
  authBody: SocialApiLoginUrlOptions
): Promise<string | undefined> {
  return new Promise((resolve) => {
    socialApi
      .post(authUrl, authBody)
      .then((data) =>
        resolve((data as { redirect_url?: string })?.redirect_url)
      )
      .catch(() => resolve(undefined));
  });
}
