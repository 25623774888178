import { handleResponse, MERCURY_API } from '../helpers/http-api';

type MercuryArticleStats = {
  views: number;
  comments: number;
  likes: number;
  favorites: number;
};

const mercuryAdapter = {
  stats: {
    get(articleId: string) {
      return fetch(`${MERCURY_API}/rpc/articles/get-stats/${articleId}`).then(
        handleResponse<MercuryArticleStats>
      );
    },
  },
};

export default mercuryAdapter;

export type MercuryAdapter = typeof mercuryAdapter;
