import {
  socialApi,
  getRequestOptions,
  handleResponse,
  SOCIAL_API,
} from '../helpers/http-api';
import type { SocialApiLoginUrlOptions } from '../presentation/auth-popup/components/social-networks/do-social-auth';
import {
  doSocialAuthFetch,
  doSocialAuthRequestInNewWindow,
  forceCloseAuthWindow,
  initAuthWindow,
} from '../presentation/auth-popup/components/social-networks/do-social-auth';
import type { LoginProviderName } from '../presentation/auth-popup/components/social-networks/providers';
import { getMindboxDeviceUuid } from '../helpers/get-mindbox-device-uuid';
import { phoneCompress } from '../helpers/phone-compress';
import { concatAuthParamsToQuery } from '../presentation/auth-popup/helpers/concat-auth-params-to-query';
import type { components } from './types/social-api-types';

export type LoginParams = {
  pointOfContact: string;
  promocode?: string;
};

export type ApiFeatureFlagsResponse = {
  flags: string[];
};

export type ApiMergeAccountResponse = {
  id: number;
  email: string;
};

export type PublicProfile = {
  id: number;
  name: string;
  image: string;
  karma: number;
  obtained_achievements: Array<{
    slug: string;
    title: string;
    image: string;
  }>;
  // Добавляем только те поля, которые нужны для отображения на этапе мерджа аккаунтов
  [k: string]: unknown;
};

const socialAdapter = {
  login: {
    async email(email: string, loginParams: LoginParams): Promise<void> {
      // Временно меняет ручку, для защиты от ddos на email1
      return socialApi.post('/account/login/email1/', {
        email,
        point_of_contact: loginParams.pointOfContact,
        promocode: loginParams.promocode,
      });
    },

    async socialNetwork(
      provider: LoginProviderName,
      loginParams: LoginParams,
      phone?: string
    ): Promise<boolean> {
      initAuthWindow();

      const loginUrlParams: SocialApiLoginUrlOptions = {
        provider,
        point_of_contact: loginParams.pointOfContact,
        promocode: loginParams.promocode,
      };

      const mindboxDeviceUuid = getMindboxDeviceUuid();
      if (mindboxDeviceUuid) {
        loginUrlParams.tj_device_id = mindboxDeviceUuid;
      }

      let authUrl = `/account/login/`;

      if (phone) {
        authUrl = concatAuthParamsToQuery(authUrl, {
          phone: phoneCompress(phone),
        });
      }

      const authRedirect = await doSocialAuthFetch(authUrl, loginUrlParams);
      if (!authRedirect) {
        forceCloseAuthWindow();
        return Promise.reject();
      }

      return doSocialAuthRequestInNewWindow(authRedirect);
    },
    async ssoRequestAuthUrl(
      phone: string,
      loginParams: LoginParams
    ): Promise<string | undefined> {
      const loginUrlParams: SocialApiLoginUrlOptions = {
        provider: 'tinkoff_sso',
        point_of_contact: loginParams.pointOfContact,
        promocode: loginParams.promocode,
      };

      const mindboxDeviceUuid = getMindboxDeviceUuid();
      if (mindboxDeviceUuid) {
        loginUrlParams.tj_device_id = mindboxDeviceUuid;
      }

      const authUrl = concatAuthParamsToQuery('/account/login/', {
        phone: phoneCompress(phone),
        theme: 'journal',
      });
      const authRedirect = await doSocialAuthFetch(authUrl, loginUrlParams);
      if (!authRedirect) {
        return Promise.reject();
      }

      return Promise.resolve(authRedirect);
    },

    async ssoAuth(query: string): Promise<{ status: string; payload: string }> {
      return socialApi.get(`/account/tinkoff/login/callback/?${query}`);
    },
  },
  validateCode: {
    async phone(phone: string, code: string, pointOfContact?: string) {
      return fetch(
        `${SOCIAL_API}/account/login/phone/validate-code/`,
        getRequestOptions('POST', {
          phone,
          code,
          point_of_contact: pointOfContact,
        })
      ).then((r) => {
        if (r.status === 201) {
          return r;
        }
        return handleResponse(r);
      });
    },

    async email(email: string, code: string, pointOfContact?: string) {
      return fetch(
        `${SOCIAL_API}/account/login/email/validate-code/`,
        getRequestOptions('POST', {
          email,
          code,
          point_of_contact: pointOfContact,
        })
      ).then((r) => {
        if (r.status === 201) {
          return r;
        }
        return handleResponse(r);
      });
    },
  },

  async personalData(name: string, image?: File) {
    let imgUrl;
    if (image) {
      const img: any = await socialApi.uploadImage(image);

      if (img?.links?.original) {
        imgUrl = img.links.original;
      }
    }

    return socialApi.put('/account/profile/', {
      name,
      image: imgUrl,
    });
  },

  logout() {
    return fetch(
      `${SOCIAL_API}/account/logout/`,
      getRequestOptions('POST')
    ).then((result) => {
      if (result.status === 204) {
        return 'success';
      }
      return 'fail';
    });
  },

  profile() {
    return socialApi.get('/account/profile/');
  },

  publicProfileById(id: number) {
    return socialApi.get<PublicProfile>(`/profiles/${id}/`);
  },

  flags(): Promise<ApiFeatureFlagsResponse> {
    return socialApi.get('/flags/');
  },
  merge: {
    post(mergeAccountId: number): Promise<void> {
      return socialApi.post('/account/merge/', {
        account_to_merge: mergeAccountId,
      } as components['schemas']['MergeAccountRequestRequest']);
    },
    validateCode(email: string, code: string): Promise<void> {
      return socialApi.post('/account/merge/validate-code/', {
        email,
        code,
      } as components['schemas']['AccountMergeValidateCodeRequestRequest']);
    },
    get(): Promise<ApiMergeAccountResponse> {
      return socialApi.get<ApiMergeAccountResponse>('/account/merge/');
    },
  },
  subscriptions: {
    get(): Promise<{
      email: string[];
      email_is_saved: boolean;
      flows: string[];
    }> {
      return socialApi.get('/account/subscriptions/');
    },

    async subscribeToTopic(
      email: string,
      topics: string[],
      pointOfContact: string
    ) {
      return socialApi.post('/notifications/subscribe/', {
        email,
        topics,
        point_of_contact: pointOfContact,
      });
    },
  },
  meta: {
    get: {
      favorites(params?: { limit?: number; offset?: number }): Promise<{
        data: { article_path: string; article_uuid: string }[];
        count: number;
      }> {
        let uri = '/account/favorites/';
        if (params) {
          // Нужно преобразовать объект в Record<string, string>
          const mappedParams: Record<string, string> = {};
          for (const [key, value] of Object.entries(params)) {
            mappedParams[key] = value.toString();
          }
          const urlSearchParams = new URLSearchParams(mappedParams);

          uri += `?${urlSearchParams}`;
        }
        return socialApi.get(uri, true);
      },

      votes(): Promise<
        { article_path: string; article_uuid: string; user_vote: number }[]
      > {
        return socialApi.get('/account/article-votes/');
      },
    },
    add: {
      favorite(
        article_uuid: string
      ): Promise<{ article_path: string; article_uuid: string }> {
        return socialApi.post('/account/favorites/', {
          article_uuid,
        });
      },

      async vote(article_uuid: string): Promise<void> {
        return socialApi.post(`/articles/${article_uuid}/vote/`, {
          method: 'up',
        });
      },
    },
    remove: {
      favorite(article_uuid: string): Promise<Response> {
        return socialApi.delete(`/account/favorites/${article_uuid}/`);
      },

      vote(article_uuid: string): Promise<void> {
        return socialApi.post(`/articles/${article_uuid}/vote/`, {
          method: 'down',
        });
      },
    },
  },
};

export default socialAdapter;

export type SocialAdapter = typeof socialAdapter;
