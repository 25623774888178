import type {
  ArticlesMeta,
  Profile,
  RecentSocialId,
  Subscriptions,
  ArticleStats,
} from '../types';
import makeResource from './make';

/* 
  У всех ресурсов по умолчанию должно стоять undefined значение.
  Это нужно для того, чтобы мы могли понимать, завершился запрос к ресурсу или ещё нет.
  Если запрос завершился, но данных нет, ставим null.
*/
const [profile, setProfile] = makeResource<Profile>(undefined);
const [articlesMeta, setArticlesMeta] = makeResource<ArticlesMeta>(undefined);
const [subscriptions, setSubscriptions] =
  makeResource<Subscriptions>(undefined);
const [recentSocialId, setRecentSocialId] =
  makeResource<RecentSocialId>(undefined);
const [articleStats, setArticleStats] = makeResource<ArticleStats>(undefined);

export {
  profile,
  setProfile,
  articlesMeta,
  setArticlesMeta,
  subscriptions,
  setSubscriptions,
  recentSocialId,
  setRecentSocialId,
  articleStats,
  setArticleStats,
};
